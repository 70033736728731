/* // FileUpload.css */
.dropzone {
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border 0.3s ease;
  }
  
  .dropzone:hover {
    border-color: #aaaaaa;
  }
  
  .uploaded-files {
    margin-top: 20px;
  }
  
  .uploaded-file {
    background-color: #f9f9f9;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  
  .uploaded-file-icon {
    font-size: 24px;
    margin-right: 10px;
    color: #d32f2f;
  }
  
  .uploaded-file-name {
    flex: 1;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  
/* CSS TAB */

.tabs ul {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.tabs li {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px 5px 0 0;
  margin-left: 2px;
}

.tabs li.is-active {
  background-color: #fff;
  border-bottom: none;
}

.tab-content {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0 0 5px 5px;
}
